import { render, staticRenderFns } from "./_.vue?vue&type=template&id=06f7f506"
import script from "./_.vue?vue&type=script&lang=js"
export * from "./_.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotFound: require('/home/ubuntu/master/monorepo_apps/trywilder.com/components/not-found.vue').default,Metadata: require('/home/ubuntu/master/monorepo_apps/trywilder.com/components/metadata.vue').default})
